@import '@styles/sass/mixins/rem';

.paragraph_header {
  &__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: rem(26);
      line-height: rem(32);
      font-weight: bold;
    }
  }
}
