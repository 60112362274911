@import '@styles/sass/mixins/rem';

.markdown {
  &__title {
    h1 {
      font-size: rem(32);
      line-height: rem(38);
      font-weight: bold;
    }

    h2 {
      font-size: rem(26);
      line-height: rem(31);
      font-weight: bold;
    }

    h3 {
      font-size: rem(22);
      line-height: rem(28);
      font-weight: bold;
    }

    h4 {
      font-size: rem(18);
      line-height: rem(25);
      font-weight: bold;
    }

    h5 {
      font-size: rem(14);
      line-height: rem(20);
      font-weight: bold;
    }

    h6 {
      font-size: rem(12);
      line-height: rem(15);
      font-weight: bold;
    }

    p {
      font-size: rem(16);
      line-height: rem(24);
    }

    a {
      text-decoration: underline;
      color: var(--primaryColor);
      &:hover {
        color: var(--buttonPrimaryBgHover);
      }
    }

    ul,
    ol {
      margin: 0;
      padding: 10px 0 10px 20px;
    }

    ol {
      list-style: decimal;
    }
  }
}
