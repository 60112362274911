@import '@styles/sass/mixins/rem';

.footer {
  &__markdown {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: rem(20) 0;
    }

    span,
    p {
      font-size: rem(14) !important;
      line-height: rem(20) !important;
      margin: rem(16) 0;
    }
  }
}
